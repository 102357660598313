@import './_variables'
@import './_mixins'

*
  box-sizing: border-box
  outline: none

html,
body
  height: 100vh
  overflow-x: hidden

body 
  position: relative
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $main-text-color
  background-color: $main-background-color
  font-size: $main-text-size

code 
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

img
  max-height: calc(100vh - $main-text-size * 5 - var(--Grid-rowSpacing) * 4 - 48px)

figure
  text-align: center

.pagination
  ul
    justify-content: center

a
  color: inherit